import React from 'react';
import './Carousel.scss';

function Carousel({ images }) {
  return (
    <div className="Carousel">
      <ul>
        {images.map((i) => <li><img alt='img' src={i}/></li>)}
      </ul>
    </div>
  );
};

export default Carousel;


