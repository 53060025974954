import './App.scss';
import Home from './pages/Home.js';
import Contact from './pages/Contact.js';
import Terms from './pages/Terms.js';
import Privacy from './pages/Privacy.js';
import NoMatch from './pages/NoMatch.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { Routes, Route, Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <Header/>
      <Outlet />
      <Footer/>
    </div>
  );
}

export default App;
